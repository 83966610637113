$(document).ready(() => {
    const currentUrl = window.location.href;
    const $wrapper = $('.b-gifts_result-filters-wrapper');
    const $allFilter = $('.b-gifts_result-filters-wrapper .b-gifts_result-filter-all');
    const $purchasedFilter = $('.b-gifts_result-filters-wrapper .b-gifts_result-filter-purchased');
    const $remainingFilter = $('.b-gifts_result-filters-wrapper .b-gifts_result-filter-remaining');
    const $favoritesFilter = $('.b-gifts_result-filters-wrapper .b-gifts_result-filter-favorites');

    if ($wrapper) {
        if (currentUrl.indexOf('GetPurchased') !== -1) {
            $purchasedFilter.addClass('js-active');
        } else if (currentUrl.indexOf('GetRemaining') !== -1) {
            $remainingFilter.addClass('js-active');
        } else if (currentUrl.indexOf('GetFavorites') !== -1) {
            $favoritesFilter.addClass('js-active');
        } else {
            $allFilter.addClass('js-active');
        }

        $wrapper.click((e) => {
            const $filter = $(e.target);
            const $button = $filter.parent('.b-gifts_result-filter');

            if ($button && $button.hasClass('js-active')) {
                e.preventDefault();
            }
        });
    }
});
